import React from 'react'
import { MapPin, Smartphone, Mail, Facebook } from 'react-feather'
import { graphql } from 'gatsby'

import FormSimpleAjax from '../components/FormSimpleAjax'
import Content from '../components/Content'
import GoogleMap from '../components/GoogleMap'
import Layout from '../components/Layout'
import './ContactPage.css'

export const ContactPageTemplate = ({
  body,
  address,
  phone,
  email,
  locations
}) => (
  <main className="Contact">
    <section className="section Contact--Section1">
      <div className="container Contact--Section1--Container">
        <div>
          <Content source={body} />
          <div className="Contact--Details">
            {address && (
              <a
                className="Contact--Details--Item"
                href={`https://www.google.com.au/maps/search/${encodeURI(
                  address
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <MapPin width="48"/> {address}
              </a>
            )}
            {phone && (
              <a className="Contact--Details--Item" href={`tel:${phone}`}>
                <Smartphone /> {phone}
              </a>
            )}
            {email && (
              <a className="Contact--Details--Item" href={`mailto:${email}`}>
                <Mail /> {email}
              </a>
            )}
            {Facebook && (
              <a 
              className="Contact--Details--Item" 
              href="https://www.facebook.com/McFarlandTransport" 
              target="_blank"
              >
                <Facebook /> {'Facebook - McFarland Transport'}
              </a>
            )}
            <GoogleMap locations={locations} />
          </div>
        </div>

        <div className="ContactFormDiv">
          <FormSimpleAjax name="Simple Form Ajax" />
        </div>
      </div>
    </section>

  </main>
)

const ContactPage = ({ data: { page } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <ContactPageTemplate {...page.frontmatter} body={page.html} />
  </Layout>
)

export default ContactPage

export const pageQuery = graphql`
  query ContactPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        template
        featuredImage
        address
        phone
        email
        locations {
          mapLink
          lat
          lng
        }
      }
    }
  }
`
